var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"trading-list"},[_c('div',{staticClass:"section-label"},[_vm._v(_vm._s(_vm.$t("trading.subtitle")))]),_c('label',{attrs:{"color":"description color-text-medium"}},[_vm._v(" "+_vm._s(_vm.$t("trading.description"))+" ")]),(_vm.projects)?_c('ExporoTable',{attrs:{"isLoading":_vm.isLoading,"data":_vm.projectsData,"items":_vm.$t('trading.dataTable.label', { context: 'projects' }),"showPagination":true},on:{"update":_vm.onTableUpdate}},[_vm._l((_vm.projectsData),function(row,i){return _c('template',{attrs:{"slot-scope":row},slot:`${
        row[_vm.$t('trading.dataTable.label', { context: 'offers' })].slotId
      }`},[_c('div',{key:`button-${
          row[_vm.$t('trading.dataTable.label', { context: 'offers' })].slotId
        }`},[_c('button',{staticClass:"light circle",attrs:{"title":_vm.$t('trading.dataTable.label', { context: 'offers' })},on:{"click":function($event){return _vm.setActiveProject(i)}}},[(i === _vm.activeProject)?_c('i',{staticClass:"material-icons exporo-table__navigation-icon"},[_vm._v(" expand_less ")]):_c('i',{staticClass:"material-icons exporo-table__navigation-icon"},[_vm._v(" expand_more ")])])])])}),_vm._l((_vm.projectsData),function(row,i){return _c('template',{attrs:{"slot-scope":row},slot:`row-active-${i}`},[(i === _vm.activeProject)?_c('div',{key:`row-active-${i}`,staticClass:"trading-list__detail-expand"},[_c('ExporoTable',{attrs:{"isLoading":_vm.isLoading,"data":_vm.sellOffersTableData(i),"items":_vm.$t('trading.dataTable.label', { context: 'offers' }),"showPagination":true},on:{"update":_vm.onTableUpdate}},[_vm._l((_vm.sellOffersTableData(i)),function(row){return _c('template',{attrs:{"slot-scope":row},slot:`${
              row[_vm.$t('trading.dataTable.label', { context: 'buy' })].slotId
            }`},[_c('div',{key:`buy-${
                row[_vm.$t('trading.dataTable.label', { context: 'buy' })].slotId
              }`},[_c('router-link',{staticClass:"app-header__top-link btn light primary",attrs:{"to":`/depot/trading/buy-sell-offer/${row[_vm.$t('trading.dataTable.label', { context: 'buy' })].value}`,"title":_vm.$t('trading.dataTable.label', { context: 'buy' })}},[_vm._v(" "+_vm._s(_vm.$t('trading.dataTable.label', { context: 'buy' }))+" ")])],1)])})],2)],1):_vm._e()])})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }