
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Asset, SellOffer } from '@/store/trading/types';
import { currency, centsToEuro } from '@/filters/currency';
import { dateString } from '@/filters/date';
import ExporoTable from '@/components/_common/Table.vue';
import { Pagination } from '@/store/types';

@Component({
  name: 'trading-list',
  filters: {
    currency,
    dateString,
    centsToEuro,
  },
  components: {
    ExporoTable,
  },
})
export default class TransactionsOverviewCard extends Vue {
  @Prop({ default: undefined, required: true }) projects?: Asset[];
  @Prop({ default: undefined, required: true }) isLoading?: Boolean;
  @Getter('trading/getSellOffersByAssetId') getSellOffersByAssetId?: Function;
  @Getter('trading/getAssetLowestOffer') getAssetLowestOffer?: Function;

  activeProject: number = null;
  public pagination: Pagination = {
    page:1,
    perPage: 0,
  }

  get projectsData(): Array<any> {

    const projectsData: Array<object> = [];

    if (this.projects) {
      this.projects.map(project => {

          projectsData.push({
            [ this.$t('trading.dataTable.label', { context: 'title' }) ]: {
              text: project.title,
            },
            [ this.$t('trading.dataTable.label', { context: 'lowestPriceTotal' }) ]: {
              text: this.getProjectLowestOfferPrice(project.asset_id),
            },
            [ this.$t('trading.dataTable.label', { context: 'pieceValue' }) ]: {
              text: this.parseAmount(project.unit_price),
            },
            [ this.$t('trading.dataTable.label', { context: 'details-page' }) ]: {
              text: this.getProjectDetailPage(project.financial_product_id),
            },
            [ this.$t('trading.dataTable.label', { context: 'offers' }) ]: {
              text: '',
              value: project.id,
              slotId: `offers-${project.id}`,
            },
          });
        });
    }

    return projectsData;
  }

  sellOffersTableData(projectIndex): Array<object> {
    const sellOffers: SellOffer[] = this.getSellOffersByAssetId(this.projects[projectIndex].asset_id);
    const tableData: Array<object> = [];

    if (sellOffers.length > 0) {
      sellOffers
       .map(offer => {
          tableData.push({
            [ this.$t('trading.dataTable.label', { context: 'offerId' }) ]: {
              text: offer.id,
            },
            [ this.$t('trading.dataTable.label', { context: 'unitPrice' }) ]: {
              text: this.$options.filters.centsToEuro(offer.unit_price),
            },
            [ this.$t('trading.dataTable.label', { context: 'available-pieces' }) ]: {
              text: offer.available,
            },
            [ this.$t('trading.dataTable.label', { context: 'totalPrice' }) ]: {
              text: this.$options.filters.centsToEuro(offer.unit_price * offer.available),
            },
            [ this.$t('trading.dataTable.label', { context: 'buy' }) ]: {
              text: '',
              value: offer.id,
              slotId: `buy-${offer.id}`,
            },
            
          });
       });
    }

    return tableData;
  }

  setActiveProject(assetId) {
    this.activeProject = this.activeProject !== assetId ? assetId : null;
  }

  getProjectLowestOfferPrice(assetId) {
    return this.getAssetLowestOffer(assetId) ? this.parseAmount(this.getAssetLowestOffer(assetId).unit_price) : '-';
  }

  getProjectDetailPage(pdpId){
    return `<a style="text-decoration: underline; color: inherit;"
      href="${process.env.VUE_APP_EXPORO_PDP_URL}/${pdpId}/reporting"
      target="_blank">
        ${this.$t('trading.dataTable.label', { context: 'details' })}
      </a>`;
  }

  parseAmount(amount) {
    return this.$options.filters.centsToEuro(amount);
  }

  onTableUpdate(val) {
    this.$emit('update', val);
  }
}
