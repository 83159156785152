
import { Component, Vue } from 'vue-property-decorator';
import TradingList from '@/components/depot/TradingList.vue';
import { Action, Getter } from 'vuex-class';
import { Asset, SellOffer } from '@/store/trading/types';

@Component({
  name: 'trading-view',
  components: {
    TradingList,
  },
})
export default class TradingView extends Vue {
  @Getter('trading/projects') projects?: Asset[];
  @Getter('trading/selloffers') sellOffers: SellOffer[]
  @Getter('trading/loading') loading?: Boolean;
  @Action('trading/fetchSellOffers') fetchSellOffers: Function;

  async mounted() {
    await this.fetchSellOffers();
  }
}
